<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Work Permit Tracking",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Work Permit Tracking",
            session: JSON.parse(localStorage.session),
            session_role_id: JSON.parse(localStorage.session).role_id,
            filter_work_type: [],
            filter_work_status: [],
            work_type_selected: [],
            work_status_selected: [],
            initiator_name: "",
            work_permit_no: "",
            date_start: "",
            date_end: "",

            dataTable: [],
            pagingTable: [],
            showDataToTable: "",
            showDataFromTable: "",
            totalDataTable: "",
            currentTablePage: "",
            itemsPerPage: "",
            searchDataTable: "",
            modal_qr: false,
            qr_path: "",
            hak_akses_add_workpermit: false,
            loadingTable: false,

            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "asc",
                filter_status: true, // untuk filtering data
                tanggal_dari: null,
                tanggal_sampai: null,
                role_id: localStorage.session_role_active_id,
            },

            // config untuk paginasi
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
                links: "",
            },
        };
    },
    mounted() {
        if (this.session.role_id == 42) {
            this.initiator_name = this.session.name;
        }
        var config_type = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/get-perizinan-sub",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config_type)
            .then((response) => {
                console.log(response, "response data type");
                this.filter_work_type = response.data.data.referensi;
                Swal.close();
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                console.log(this.axiosCatchError);
                Swal.close();
            });
        var config_status = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/status-permohonan",
            params: {
                is_tracking: true,
            },
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config_status)
            .then((response) => {
                this.filter_work_status = response.data.data[0].data;
                Swal.close();
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                console.log(this.axiosCatchError);
                Swal.close();
            });
    },
    methods: {
        getDataTable() {
            let self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit",
                params: {
                    is_tracking: true,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.loadingTable = false;
                        self.dataTable = response_data_fix.data;
                        self.pagingTable = response_data_fix.links;
                        self.showDataToTable = response_data_fix.to;
                        self.showDataFromTable = response_data_fix.from;
                        self.totalDataTable = response_data_fix.total;
                        self.currentTablePage = response_data_fix.current_page;
                        self.itemsPerPage = response_data_fix.per_page;
                        self.hak_akses_add_workpermit = response_data.data.hak_akses_add_workpermit;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        filterWorkPermit() {
            let self = this;
            self.loadingTable = true;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit",
                params: {
                    filter_work_type: self?.work_type_selected.mps_id,
                    filter_work_status: self?.work_status_selected.msp_id,
                    initiator_name: self.initiator_name,
                    work_permit_no: self.work_permit_no,
                    date_start: self.date_start,
                    date_end: self.date_end,
                    is_tracking: true,
                    length: self.tableData.length,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.loadingTable = false;
                        self.dataTable = response_data_fix.data;
                        self.pagingTable = response_data_fix.links;
                        self.showDataToTable = response_data_fix.to;
                        self.showDataFromTable = response_data_fix.from;
                        self.totalDataTable = response_data_fix.total;
                        self.currentTablePage = response_data_fix.current_page;
                        self.itemsPerPage = response_data_fix.per_page;
                        self.hak_akses_add_workpermit = response_data.data.hak_akses_add_workpermit;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        resetFilterWorkPermit() {
            this.initiator_name = '';
            this.date_start = '';
            this.date_end = '';
            this.work_permit_no = '';
            this.filter_work_type = [];
            this.filter_work_status = [];
            this.work_type_selected = [];
            this.work_status_selected = [];
        },
        toPage(url) {
            let self = this;
            self.loadingTable = true;
            var config = {
                method: "get",
                url: url,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.loadingTable = false;
                        self.dataTable = response_data_fix.data;
                        self.pagingTable = response_data_fix.links;
                        self.showDataToTable = response_data_fix.to;
                        self.showDataFromTable = response_data_fix.from;
                        self.totalDataTable = response_data_fix.total;
                        self.currentTablePage = response_data_fix.current_page;
                        self.itemsPerPage = response_data_fix.per_page;
                        self.hak_akses_add_workpermit = response_data.data.hak_akses_add_workpermit;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        exportExcel() {
            let self = this;
            let config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit/export-tracking",
                params: {
                    filter_work_type: self?.work_type_selected.mps_id,
                    filter_work_status: self?.work_status_selected.msp_id,
                    initiator_name: self.initiator_name,
                    work_permit_no: self.work_permit_no,
                    date_start: self.date_start,
                    date_end: self.date_end,
                    is_tracking: true,
                    length: self.tableData.length,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
                responseType: "blob",
            };
            axios(config)
                .then(function (response) {
                    var blob = new Blob([response.data], { type: "application/vnd-ms-excel" });
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "List Work permit Tracking.xlsx";
                    link.click();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
};
</script>
<style scoped>
.bodyborder {
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    text-align: center;
}
</style>

<template>
    <Layout>
        <PageHeader :title="title" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="form-group">
                                    <b for="">Initiator Name</b>
                                    <b-form-input id="formrow-name-input" type="text" v-model="initiator_name" :disabled="session_role_id == 42"></b-form-input>
                                </div>
                                <div class="form-group">
                                    <b for="">Initiator Date</b>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-input id="formrow-name-input" type="date" v-model="date_start" placeholde="Initator Date Start"></b-form-input>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-input id="formrow-name-input" type="date" v-model="date_end" placeholde="Initator Date End"></b-form-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <b for="">Work Permit Number</b>
                                    <b-form-input id="formrow-name-input" type="text" v-model="work_permit_no"></b-form-input>
                                </div>
                                <div class="form-group">
                                    <b for="">Work Permit Type</b>
                                    <v-select :options="filter_work_type" label="mps_nama" v-model="work_type_selected"></v-select>
                                </div>
                                <div class="form-group">
                                    <b for="">Work Permit Status</b>
                                    <v-select :options="filter_work_status" label="msp_nama" v-model="work_status_selected"></v-select>
                                </div>
                                <div class="form-group">
                                    <br />
                                    <div class="btn btn-primary btn-block" style="width: 100%" v-on:click="filterWorkPermit"><i class="fa fa-filter"></i> Filter</div>
                                </div>
                                <div class="form-group">
                                    <br />
                                    <div class="btn btn-danger btn-block" style="width: 100%" v-on:click="resetFilterWorkPermit"><i class="fa fa-filter"></i> Reset Filter</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 my-3">
                                <!-- <div class="row">
                                    <div class="col-md-12">
                                        <div class="text-end">
                                            <br />
                                            <router-link :to="{name: 'add-work-permit'}" class="btn btn-success"><i class="fas fa-file-excel"></i> Excel</router-link> 
                                            <button class="btn btn-sm btn-success m-1" @click="exportExcel"><i class="fas fa-file-excel"></i> Export Data</button>
                                        </div>
                                        
                                    </div>

                                </div> -->
                                <br />
                                <div class="row">
                                    <div class="col-md-4">
                                        <table>
                                            <tr>
                                                <td>Show</td>
                                                <td>
                                                    <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%" v-model="tableData.length" @change="filterWorkPermit()">
                                                        <option value="10" selected>10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="200">200</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </td>
                                                <td>Entries</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-md-5"></div>
                                    <div class="col-md-3 text-end">
                                        <button class="btn btn-sm btn-success m-1" @click="exportExcel"><i class="fas fa-file-excel"></i> Export Data</button>
                                    </div>
                                </div>
                                <br />
                            </div>
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table mb-0 table-bordered table-condensed table-hover">
                                        <thead class="bg-dark text-center text-white">
                                            <tr>
                                                <th style="width: 50px">No</th>
                                                <th>Work Permit Number</th>
                                                <th>Work Permit Type</th>
                                                <th>Work Permit Status</th>
                                                <th>Initiator Name</th>
                                                <th style="width: 175px">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="loadingTable">
                                                <td colspan="8" class="text-center"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                            </tr>
                                            <tr v-else-if="dataTable.length == 0">
                                                <td colspan="8" class="text-center">Data Tidak Tersedia</td>
                                            </tr>
                                            <tr v-for="(row_data, key_data) in dataTable" :key="key_data" v-else>
                                                <td class="text-center">
                                                    {{ (currentTablePage - 1) * itemsPerPage + (key_data + 1) }}.
                                                </td>
                                                <td>
                                                    <div v-if="row_data?.work_permit_no">{{ row_data?.work_permit_no }} <br /></div>
                                                    <div v-else>Data Tidak Tersedia</div>
                                                </td>
                                                <td>
                                                    <div v-if="row_data?.work_permit_type">
                                                        {{ row_data?.work_permit_type }}

                                                        <ul v-if="row_data?.work_permit_request_detail">
                                                            <li v-for="(row_type, key_type) in row_data?.work_permit_request_detail" :key="key_type">
                                                                {{ row_type.work_permit_type_nama }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div v-else>Data Tidak Tersedia</div>
                                                </td>
                                                <td>
                                                    {{ row_data?.status_berjalan?.mspk_msp_nama }}
                                                </td>
                                                <td>
                                                    {{ row_data?.user?.name }}
                                                </td>
                                                <td style="text-align: center">
                                                    <div v-if="row_data?.status_permit != 'Draft Work Plan'">
                                                        <router-link :to="{ name: 'work-permit-detail-tracking', params: { permitId: row_data.id, is_tracking: true } }" class="btn btn-sm btn-info" style="margin-right: 10px" target="_blank"><i class="fa fa-eye"></i> Detail</router-link>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="pt-4">Show {{ showDataFromTable }}-{{ showDataToTable }} From {{ totalDataTable }}</div>
                            </div>
                            <div class="col-md-6">
                                <div style="float: right !important">
                                    <nav aria-label="pagination" class="mt-3">
                                        <ul class="pagination justify-content-start">
                                            <li
                                                v-for="(page, key_page) in pagingTable"
                                                v-bind:class="{
                                                    active: page.active,
                                                }"
                                                :key="key_page"
                                                class="page-item">
                                                <div v-if="page.url" v-on:click="toPage(page.url)" class="page-link c-page-link-number">
                                                    <div v-html="page.label"></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
